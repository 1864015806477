import React, {useRef} from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';
import FooterTwo from '../common/footer/FooterTwo';
import FormThree from '../component/contact/FormThree';

const CorporateAgency = () => {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
  
    // Function to scroll to a specific section
    const scrollToSection = (section) => {
      switch (section) {
        case 'section1':
          section1Ref.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'section2':
          section2Ref.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'section3':
        section3Ref.current.scrollIntoView({ behavior: 'smooth' });
        break;
        case 'section4':
          section4Ref.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'section5':
          section5Ref.current.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          break;
      }
    };

    return (
        <>
        <SEO title="Corporate Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
        <HeaderFour scrollToSection={scrollToSection} />
        <BannerFive scrollToSection={scrollToSection} />

        <div ref={section1Ref} className="section section-padding">
            <div className="container">
            <SectionTitle 
                subtitle="Our Simple Process to"
                title="Providing Innovative <br/> Solutions"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
            />
                <div className="row">
                    <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
            </ul>

        </div>
        <div ref={section2Ref}>
            <ProjectOne parentClass="bg-color-light" />
        </div>
        <div>
            <BrandOne />
        </div>
        <div ref={section3Ref}>
            <TestimonialOne />
        </div>
        <div ref={section4Ref}>
            <CounterUpOne />
        </div>
        <div><BlogOne /></div>
        <div className="section-padding bg-color-dark">
                <div ref={section5Ref} className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Need an Estimate ?"
                                title="Let’s work together"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className="address-list">
                                <div className="address">
                                <h6 className="title">E-Mail Address</h6>
                                <p>contact@planitech.co</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Office Locations</h6>
                                <p>Melbourne, AUS <br/> Dubai, UAE</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Phone Number</h6>
                                <p>+1 530 8139501</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Describe your project</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterTwo />
        </main>
        </>
    )
}

export default CorporateAgency;